import { Box, BoxProps, chakra, forwardRef, Stack } from "@chakra-ui/react"
import { COMPANY } from "@onemile/corporate-data"

export type TelusBadgeProps = BoxProps

export const TelusBadge = forwardRef(({ ...props }: TelusBadgeProps, ref) => {
  return (
    <Box bgColor="moneiro-color-fill-black" color="#fff" ref={ref} {...props}>
      <chakra.div textStyle="12-reg" borderBottomWidth="1px">
        お気軽にご相談ください
      </chakra.div>
      <Stack spacing={1} my={2}>
        {COMPANY.offices.stores.map((store) => (
          <chakra.div textStyle="12-bold" key={store.key}>
            {store.tel && (
              <>
                {store.short}：
                <chakra.div textStyle="18-bold" mx={3}>
                  {store.tel}
                </chakra.div>
              </>
            )}
          </chakra.div>
        ))}
      </Stack>
      <chakra.div textStyle="12-bold" borderTopWidth="1px">
        10：00〜19：00（平日）
      </chakra.div>
    </Box>
  )
})
