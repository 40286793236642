import { ChevronRightIcon } from "@chakra-ui/icons"
import {
  Box,
  BoxProps,
  Center,
  chakra,
  HStack,
  HTMLChakraProps,
  Icon,
  Link,
  Stack,
  StackDivider,
} from "@chakra-ui/react"
import { FaInstagram, FaLine } from "react-icons/fa"
import { Logo } from "../logo"
import { Visible } from "../system"
import { NavLink } from "../system/NavLink"
import { TelusBadge } from "../TelusBadge"
import {
  CONSULTING_LAYOUT_LINK,
  CONSULTING_LAYOUT_LINKS,
  CONTACT_LINKS,
  FAQ_LAYOUT_LINK,
  FOR_BUSINESS_LAYOUT_LINK,
  INSTAGRAM_LAYOUT_LINK,
  LayoutLink,
  LINE_LAYOUT_LINK,
  MEDIA_LAYOUT_LINK,
  MYPAGE_LAYOUT_LINK,
  OTHER_LINKS,
  POLICY_LINKS,
  REVIEWS_LAYOUT_LINK,
  SEMINAR_LAYOUT_LINK,
  SEMINAR_LAYOUT_LINKS,
  SIMULATION_LAYOUT_LINK,
  TOP_LAYOUT_LINK,
} from "./links"

const FOOTER_ITEM_BEFORE_BAR = {
  content: `""`,
  display: "inline-block",
  background: "#fff",
  borderRadius: 10,
  height: "1px",
  width: "10px",
  marginLeft: "20px",
  marginRight: "10px",
}

export type FooterProps = {
  /** 画面下部に追従する要素があるか？ */
  haveFloatingFooter?: boolean
  isAffiliate?: boolean
} & HTMLChakraProps<"footer">

export const Footer = ({
  haveFloatingFooter,
  isAffiliate = false,
  ...props
}: FooterProps) => {
  const { base, md, other, contact, policy } = useFooter(isAffiliate)

  return (
    <>
      <Visible baseOnly>
        <chakra.footer
          bgColor="moneiro-color-fill-black"
          textStyle="12-default-bold"
          color="#fff"
          px={3}
          pt={6}
          pb={haveFloatingFooter ? 18 : 12}
          {...props}
        >
          <Stack
            spacing={4}
            divider={
              <StackDivider
                borderColor="moneiro-color-line-gray-strong"
                opacity={0.2}
              />
            }
          >
            <Stack spacing={2}>
              {base.main.map((l) => {
                return (
                  <NavLink
                    href={l.href}
                    prefetch={false}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    key={l.key}
                  >
                    <chakra.div>{l.title}</chakra.div>
                    <ChevronRightIcon boxSize="20px" />
                  </NavLink>
                )
              })}
            </Stack>
            <Stack spacing={2}>
              {base.sub.map((l) => {
                return l.external ? (
                  <Link
                    href={l.href}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    key={l.key}
                    isExternal
                  >
                    <chakra.div>{l.title}</chakra.div>
                    <ChevronRightIcon boxSize="20px" />
                  </Link>
                ) : (
                  <NavLink
                    href={l.href}
                    prefetch={false}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    key={l.key}
                  >
                    <chakra.div>{l.title}</chakra.div>
                    <ChevronRightIcon boxSize="20px" />
                  </NavLink>
                )
              })}
            </Stack>
            <Stack spacing={2}>
              {other.map((l) => {
                return (
                  <Link
                    href={l.href}
                    isExternal
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    key={l.key}
                  >
                    <chakra.div>{l.title}</chakra.div>
                    <ChevronRightIcon boxSize="20px" />
                  </Link>
                )
              })}
            </Stack>
            <Stack spacing={2}>
              {contact.map((l) => {
                return (
                  <NavLink
                    href={l.href}
                    prefetch={false}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    key={l.key}
                  >
                    <chakra.div>{l.title}</chakra.div>
                  </NavLink>
                )
              })}
            </Stack>
            <Center>
              <SNSLinks />
            </Center>
            <HStack justify="space-between" align="flex-end">
              <Stack spacing={3}>
                {policy.map((l) => {
                  return (
                    <NavLink href={l.href} prefetch={false} key={l.key}>
                      <chakra.div>{l.title}</chakra.div>
                    </NavLink>
                  )
                })}
              </Stack>
              <Logo w="107px" h="31px" _fill="#fff" />
            </HStack>
          </Stack>
          <Copyright textAlign="right" />
        </chakra.footer>
      </Visible>
      <Visible mdOnly>
        <chakra.footer
          bgColor="moneiro-color-fill-black"
          textStyle="14-bold"
          color="#fff"
          pt={12}
          pb={haveFloatingFooter ? 18 : 12}
          {...props}
        >
          <Center>
            <Box>
              <Logo w="179px" h="52px" _fill="#fff" />
              <HStack spacing={6} align="flex-start" mt={12}>
                <Stack spacing={3}>
                  {md.main.map((l) => {
                    return l.external ? (
                      <Link
                        href={l.href}
                        key={l.key}
                        display="flex"
                        alignItems="center"
                        isExternal
                      >
                        <chakra.div>{l.title}</chakra.div>
                        <OtherIcon />
                      </Link>
                    ) : (
                      <NavLink href={l.href} prefetch={false} key={l.key}>
                        <chakra.div>{l.title}</chakra.div>
                      </NavLink>
                    )
                  })}
                </Stack>
                <Stack spacing={2}>
                  <chakra.div mb={1}>セミナーで学ぶ</chakra.div>
                  {md.seminar.map((l) => {
                    return (
                      <NavLink href={l.href} prefetch={false} key={l.key}>
                        <chakra.div
                          _before={FOOTER_ITEM_BEFORE_BAR}
                          display="flex"
                          alignItems="center"
                          textStyle="12-bold"
                        >
                          {l.title}
                        </chakra.div>
                      </NavLink>
                    )
                  })}
                </Stack>
                <Stack spacing={2}>
                  <chakra.div mb={1}>相談する</chakra.div>
                  {md.consulting.map((l) => {
                    return (
                      <NavLink href={l.href} prefetch={false} key={l.key}>
                        <chakra.div
                          _before={FOOTER_ITEM_BEFORE_BAR}
                          display="flex"
                          alignItems="center"
                          textStyle="12-bold"
                        >
                          {l.title}
                        </chakra.div>
                      </NavLink>
                    )
                  })}
                </Stack>
                <Stack spacing={4}>
                  <Stack spacing={2}>
                    {other.map((l) => {
                      return (
                        <Link
                          href={l.href}
                          isExternal
                          display="flex"
                          alignItems="center"
                          _hover={{
                            textDecoration: "none",
                          }}
                          key={l.key}
                        >
                          <chakra.div>{l.title}</chakra.div>
                          <OtherIcon />
                        </Link>
                      )
                    })}
                  </Stack>
                  <Stack spacing={2}>
                    {contact.map((l) => {
                      return (
                        <NavLink
                          href={l.href}
                          display="flex"
                          alignItems="center"
                          key={l.key}
                        >
                          <chakra.div>{l.title}</chakra.div>
                        </NavLink>
                      )
                    })}
                  </Stack>
                  <TelusBadge />
                </Stack>
              </HStack>
              <HStack spacing={24} mt={9} justifyContent="space-between">
                <HStack spacing={6}>
                  {policy.map((l) => {
                    return (
                      <NavLink href={l.href} prefetch={false} key={l.key}>
                        <chakra.div>{l.title}</chakra.div>
                      </NavLink>
                    )
                  })}
                </HStack>
                <SNSLinks />
              </HStack>
              <Copyright textAlign="center" mt={5} />
            </Box>
          </Center>
        </chakra.footer>
      </Visible>
    </>
  )
}

const useFooter = (isAffiliate: boolean) => ({
  base: {
    main: filterMainLayoutLinksForAffiliate(
      BASE_MAIN_LAYOUT_LINKS,
      isAffiliate
    ),
    sub: BASE_SUB_LAYOUT_LINKS,
  },
  md: {
    main: filterMainLayoutLinksForAffiliate(MD_MAIN_LAYOUT_LINKS, isAffiliate),
    seminar: SEMINAR_LAYOUT_LINKS,
    consulting: CONSULTING_LAYOUT_LINKS,
  },
  other: OTHER_LINKS,
  contact: CONTACT_LINKS,
  policy: POLICY_LINKS,
})

export const filterMainLayoutLinksForAffiliate = (
  links: LayoutLink[],
  isAffiliate: boolean
): LayoutLink[] =>
  links.filter((link) => !(isAffiliate && link.key === "simulation"))

const BASE_MAIN_LAYOUT_LINKS: LayoutLink[] = [
  TOP_LAYOUT_LINK,
  SIMULATION_LAYOUT_LINK,
  SEMINAR_LAYOUT_LINK,
  CONSULTING_LAYOUT_LINK,
  MYPAGE_LAYOUT_LINK,
]

const BASE_SUB_LAYOUT_LINKS: LayoutLink[] = [
  FAQ_LAYOUT_LINK,
  REVIEWS_LAYOUT_LINK,
  MEDIA_LAYOUT_LINK,
  FOR_BUSINESS_LAYOUT_LINK,
]

const MD_MAIN_LAYOUT_LINKS: LayoutLink[] = [
  TOP_LAYOUT_LINK,
  SIMULATION_LAYOUT_LINK,
  FAQ_LAYOUT_LINK,
  REVIEWS_LAYOUT_LINK,
  MEDIA_LAYOUT_LINK,
  FOR_BUSINESS_LAYOUT_LINK,
]

const SNSLinks = () => (
  <HStack spacing={{ base: 4, md: 2 }} fontSize="20px" lineHeight="1">
    <Visible mdOnly>
      <chakra.div textStyle="16-bold">Follow me!</chakra.div>
    </Visible>
    <Link href={INSTAGRAM_LAYOUT_LINK.href} title={INSTAGRAM_LAYOUT_LINK.title}>
      <Icon as={FaInstagram} />
    </Link>
    <Link href={LINE_LAYOUT_LINK.href} title={LINE_LAYOUT_LINK.title}>
      <Icon as={FaLine} />
    </Link>
  </HStack>
)

const Copyright = ({ ...props }: HTMLChakraProps<"div">) => {
  return (
    <chakra.div
      fontWeight={500}
      fontSize="10px"
      lineHeight="20px"
      letterSpacing="0.04em"
      mt={1}
      {...props}
    >
      © Monicle Financial Inc.
    </chakra.div>
  )
}

const OtherIcon = () => {
  const baseStyle: BoxProps = {
    w: "16px",
    h: "11px",
    bgColor: "#fff",
  }

  return (
    <Box ml={3} pos="relative">
      <Box {...baseStyle} />
      <Box
        {...baseStyle}
        borderTopWidth="1.5px"
        borderRightWidth="1.5px"
        borderColor="moneiro-color-fill-black"
        pos="absolute"
        top="2px"
        left="-2px"
      />
    </Box>
  )
}
